/*imported stylesheets*/
/* Bootstrap */
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';

/* Motili-UI */
@import '~motili-ui/dist/assets/styles/thirdPartyStyles.css';

/* local stylesheets*/
@import './styles/colors.scss';
@import './styles/base.scss';
