* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

div#root-dashboard-mf-app {
    height: 100%;
    > div.layout {
        height: 100%;
    }
}

body {
    font-family: 'Nunito Sans', sans-serif;
}

pre {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 14px !important;
    line-height: 1.42857 !important;
    padding: 0 !important;
    word-break: break-word;
}

a {
    color: $motili-light-blue;
    cursor: pointer;
}

.content-spacing > *:not(:first-child) {
    padding-top: 10px;
}

.content-spacing > *:last-child {
    padding-bottom: 10px;
}

// dont remove this...
//https://github.com/FortAwesome/Font-Awesome/issues/1540
.fa-angle-times::before {
    content: '\F105\F104';
    letter-spacing: -0.15em;
}
